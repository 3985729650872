import { homeLoading, homeError, setHomeList } from "../../slices/homeSlice";
import { getService, updateService } from "../../services/home";

export const getAction = () => async (dispatch) => {
  dispatch(homeLoading());
  try {
    const response = await getService();
    if (response?.error) {
      dispatch(homeError(response.error));
      return;
    } else {
      dispatch(setHomeList(response));
      return;
    }
  } catch (error) {
    dispatch(homeError(error.message));
    return;
  }
};

export const updateAction = (formData) => async (dispatch) => {
  const table = formData.get("table");
  const id = formData.get("id");
  dispatch(homeLoading());
  try {
    const response = await updateService(formData);
    if (response?.message) {
      dispatch(getAction());
      return;
    } else {
      dispatch(
        homeError(`Failed to update row with ID ${id} in ${table} table`)
      );
      return;
    }
  } catch (error) {
    dispatch(homeError(error.message));
    return;
  }
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    homeLoading(state) {
      state.loading = true;
      state.error = null;
    },
    homeError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setHomeList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { homeLoading, homeError, setHomeList } = homeSlice.actions;

export default homeSlice.reducer;

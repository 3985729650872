import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import homeReducer from "../slices/homeSlice";
import logsReducer from "../slices/logsSlice";

const store = configureStore({
  reducer: {
    home: homeReducer,
    logs: logsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunkMiddleware),
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;

import axios from "axios";

export const getService = async () => {
  return await axios
    .get(`${process.env.REACT_APP_BASE_URL}/homepage`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Error retrieving Homepage data: ${error.message}`);
    });
};

export const updateService = async (formData) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/homepage/update/${formData.get(
        "table"
      )}/${formData.get("id")}`,
      formData
    );

    await logService(formData.get("username"), "Update", formData.get("table"));

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logService = async (username, actionType, tableName) => {
  try {
    await axios.post(`${process.env.REACT_APP_BASE_URL}/logs`, {
      username,
      actionType,
      tableName,
      timestamp: new Date(),
    });
  } catch (error) {
    throw error;
  }
};
